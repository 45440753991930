<template>
  <div v-if="categories !== null" class="mt-8">
       <v-btn-toggle tile v-for="(category, index) in categoryData" :key="index" borderless class="categories-holder" >
            <v-btn :color="`${zamuPurple2}`" :class="{'active-category' : active === index }" @click="setCategory(index)">
               <v-icon :class="{'active-category-icon' : active === index }" class="category-icon">mdi-chevron-right</v-icon>
            </v-btn>
             <v-btn depressed  color="white" class="category-name"  align="left" justify="left">
                {{ category.category }}
            </v-btn>
        </v-btn-toggle>
         <v-pagination v-model="currentPage"  class="zamu-pagination mt-5" :length="pagination.lastPage" :total-visible="7" ></v-pagination>

  </div>
</template>

<script>
import coreMixin from '@/mixins/coreMixin';
import colorMixin from '@/mixins/colorMixin';


export default {
    name: 'Categories',
    mixins: [coreMixin, colorMixin],

    data() {
        return {
            categories: null,
            currentPage: 1,
            active: 0,

        };
    },
    computed: {
        categoryData () {
            return this.categories !== null ? this.categories.data : null;
        },
        pagination () {
            let data = null;
            if (this.categories !== null) {
                 data = {
                    total: this.categories.total,
                    perPage: this.categories.perPage,
                    page: this.categories.page,
                    lastPage: this.categories.lastPage,
                };
            }
            return data;

        },

    },
    watch: {
        async currentPage(page) {
             this.categories = await this.retrieveActiveCategories(page);

        },
    },
    async mounted() {
        this.categories = await this.retrieveActiveCategories(this.currentPage);

    },
    methods: {
        setCategory(index) {
            this.active = index;
        },
    }

}
</script>

<style>

</style>